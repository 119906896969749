@import url('https://fonts.googleapis.com/css2?family=Inter:wght@700&display=swap');
.wrapper {
  background-color: #F5FDFF;
  min-height: 100vh;
  position: relative;
}
.main {
  flex: 1 1 auto;
}
.container {
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin: 0 auto;
  height: 100%;
  max-width: 1390px;
}
/* ============================================================================================================================= */
.header {
  display: flex;
  row-gap: 16px;
  flex-direction: column;
}
.top {
  padding: 12px 0 4px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightPart {
  display: flex;
  align-items: center;
  column-gap: 50px;
}
.helpButton {
  font-weight: 500;
  color: #1c5365;
  display: flex;
  align-items: center;
  column-gap: 8px;
  &__icon {
    img {
      max-width: 100%;
    }
  }

  &__label {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 15px;
  }
}

.logo img {
  max-width: 100%;
}

.body {
  height: 80px;
  color: #05222B;
  border-radius: 8px;
  background: #91D2E8;
  padding: 12px 60px;
  display: flex;
  justify-content: space-between;
  column-gap: 30px;

  &__right {
    column-gap: 20px;
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__top,
  &__bottom {
    align-items: center;
    column-gap: 20px;
    display: flex;
    // justify-content: space-between;
  }
}
.company {
  display: flex;
  column-gap: 24px;
  align-items: center;
  &__logo {
    & img {
      max-width: 100%;
    }
  }
  &__text {
    font-weight: 700;
    font-size: 24px;
    line-height: 150%;
  }
}
.infoItem {
  &__name {
    white-space: nowrap;
    line-height: 120%;
    margin-bottom: 4px;
    font-size: 14px;
    color: #000;
  }
  &__label {
    white-space: nowrap;
    font-size: 20px;
    line-height: 120%;
    font-weight: 700;
    text-transform: uppercase;
  }
}
.progressbar {
  display: flex;
  align-items: center;
  column-gap: 14px;
  width: 243px;
}
.progressbar__line {
  height: 4px;
  width: 100%;
  overflow: hidden;
}
/* ==========FOOTER========================================================================================================== */
.footer {
  color: #05222B;
  background-color: #91D2E8;
  height: 110px;
  padding: 20px 100px;
  justify-content: space-between;
  display: flex;
  margin-bottom: 26px;
  border-radius: 8px;
  align-items: center;
}
.item {
  display: flex;
  column-gap: 16px;
  align-items: center;
  &__image {
    width: 80px;
    height: 70px;
    position: relative;
    & img {
      max-width: 100%;
    }
  }
  &__centerIcon {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 12px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    & img {
      max-width: 100%;
    }
  }
  &__body {
  }
  &__title {
    font-size: 22px;
    line-height: 150%;
    font-weight: 700;
    text-transform: uppercase;
  }
  &__text {
    line-height: 150%;
    font-size: 20px;
    font-weight: 400;
  }
}

@media (max-width: 1189.98px) {
  .wrapper {
    row-gap: 8px;
  }
  .body {
    padding-left: 50px;
    padding-right: 50px;
    column-gap: 60px;
  }
  .company {
    &__text {
      font-size: 20px;
    }
  }
  .infoItem {
    &__name {
      font-size: 14px;
    }
    &__label {
      font-size: 16px;
    }
  }
  .footer {
    padding-left: 50px;
    padding-right: 50px;
  }
  .item {
    &__title {
      font-size: 20px;
    }
    &__text {
      font-size: 17px;
    }
  }
}
@media (max-width: 1080px) {
  .body {
    column-gap: 10px;
  }
  .progressbar {
    width: auto;
    &__line {
      width: 105px;
    }
  }
}

@media (max-width: 991.98px) {
  .body {
    padding-left: 25px;
    padding-right: 25px;
    column-gap: 30px;
    &__right {
      justify-content: flex-end;
      column-gap: 24px;
    }
    &__top,
    &__bottom {
      text-align: center;
      flex-direction: column;
    }
  }
  .company {
    &__text {
      font-size: 18px;
    }
  }
  .infoItem {
    display: flex;
    column-gap: 12px;
    justify-content: flex-start;
    width: 100%;
    &__label {
      font-size: 14px;
    }
  }
  .footer {
    flex-wrap: wrap;
    padding-left: 25px;
    padding-right: 25px;
    height: auto;
  }
  .item {
    &__title {
      font-size: 19px;
    }
    &__text {
      font-size: 17px;
    }
    &__image {
      width: 50px;
      height: 40px;
    }
    &__centerIcon {
      font-size: 7px;
      row-gap: 10px;
    }
  }
}

@media (max-width: 840.98px) {
  .footer {
    justify-content: flex-start;
  }
}

@media (max-width: 700.98px) {
  .company {
    flex-direction: column;
    row-gap: 4px;
    justify-content: center;
    &__logo {
      width: 40px;
      height: 40px;
    }
    &__text {
      font-size: 11px;
      line-height: 130%;
      text-align: center;
    }
  }
  .body {
    padding: 10px;
    flex-direction: row;
    column-gap: 22px;
    height: 100px;
    align-items: center;
    justify-content: space-between;
    &__right {
      max-width: 230px;
      align-items: flex-start;
      flex-direction: column;
    }
    &__top,
    &__bottom {
      row-gap: 5px;
      width: 100%;
    }
  }
  .infoItem {
    white-space: nowrap;
    display: flex;
    justify-content: space-between;
    column-gap: 13px;
    margin-bottom: 0;
    width: 100%;
    &__progressbar {
      order: 4;
    }
    &__name {
      font-size: 12px;
      line-height: 120%;
    }
    &__label {
      font-size: 14px;
      text-transform: none;
    }
  }

  .footer {
    height: 80px;
    justify-content: center;
    margin-bottom: 10px;
    row-gap: 10px;
    padding: 10px;
    row-gap: 8px;
    column-gap: 8px;
  }
  .item {
    white-space: nowrap;
    align-items: center;
    column-gap: 8px;
    &__title {
      font-size: 14px;
      line-height: 100%;
    }
    &__text {
      line-height: 150%;
      font-size: 12px;
    }
    &__centerIcon {
      font-size: 6px;
    }
  }
}
@media (max-width: 850.98px) {
  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    height: auto;
  }
  .footer .item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }

  .footer .item:nth-child(3) {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 450px) {
  .footer {
    display: flex;
    align-items: center;
    height: auto;
  }
  .footer .item {
    justify-content: start;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 5px;
  }
  .footer .item:nth-child(3) {
    width: 100%;
    justify-content: start;
  }
}
