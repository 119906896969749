.body {
  max-width: 800px;
}

.title {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 24px;
}
.bodyContainer {
  column-gap: 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  &_second {
    align-items: center;
  }
}
.leftPart {
  justify-content: center;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
.yourLink {
}
.uploadFile {
  flex: 1 1 auto;
}
.preview {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  flex: 0 1 40%;
}
.label {
  margin-bottom: 12px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 12px;
}
.testLinkLabel {
  text-align: left;
  color: #29a4cc;
  padding: 8px 11px;
  text-transform: uppercase;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
  &:hover {
    text-decoration: underline;
  }
}
.grayLabel {
  color: #6a6d6e;
  margin-bottom: 12px;
  line-height: 150%;
  font-size: 14px;
  font-weight: 700px;
}
.variants {
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  column-gap: 15px;
  &__item {
    flex: 0 1 25%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__image {
    flex: 1 1 auto;
    width: 130px;
    height: 114px;
    margin-bottom: 16px;
    & img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__text {
    text-align: center;
    font-weight: 600;
    line-height: 150%;
    font-size: 14px;
    color: #000;
    & span {
      font-weight: 400;
    }
  }
}
.fourthVariant {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  column-gap: 20px;
  margin-bottom: 32px;
}

.previewTile {
  width: 130px;
  height: 130px;

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
    width: 100%;
  }

  &__image {
    z-index: 3;
    width: 130px;
    height: 130px;
    position: absolute;
    & img {
      width: 130px;
      height: 130px;
      object-fit: contain;
    }
  }

  &__image1 {
    z-index: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    object-fit: cover;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__center {
    z-index: 1;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: contain;
    & img {
      width: 130px;
      height: 130px;
      object-fit: cover;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
      -webkit-clip-path: polygon(25% 5%, 75% 5%, 100% 50%, 75% 95%, 25% 95%, 0% 50%);
    }
  }

  &__centerHexagon {
    width: 100%;
    height: 100%;
    background-color: #fff;
  }
}
.contactInformation {
  max-width: 556px;
}
/* ============================================================================================================================= */
.blueButton,
.backButton {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: all 0.3s ease 0s;
  text-align: center;
  &:hover {
    background-color: #2490b3;
  }
}
.blueButton {
  column-gap: 16px;
  padding: 8px 22px;
  color: #f5fdff;
  background-color: #29a4cc;
}
.backButton {
  padding: 9px 22px;
  color: #29a4cc;
  background-color: transparent;
  column-gap: 8px;
  & svg {
    width: 12px;
    height: 16px;
  }
  &:hover {
    background-color: #dee3e4;
  }
}

.buttonWrapper {
  width: fit-content;
  position: relative;
  text-align: center;
  margin: auto;
}
.buttonLabel {
  cursor: inherit;
  font-family: 'Roboto', sans-serif;
  text-transform: uppercase;
  background: #29a4cc;
  color: #f5fdff;
  padding: 14px 22px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  display: inline-block;
}
.fileInput {
  cursor: inherit;
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
.previewTitle {
  font-size: 16px;
  font-weight: 600;
  color: #101010;
  margin-bottom: 16px;
}
.confirmationText {
  display: block;
  margin-bottom: 32px;
  color: #101010;
  font-size: 16px;
  line-height: 150%;
  font-weight: 400;
  & span {
    font-weight: 700;
  }
}
.delete {
  margin-bottom: 16px;
  &__name {
    font-size: 15px;
    font-weight: 500;
    color: #05222b;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    & svg {
      color: #d32f2f;
      width: 15px;
      height: 15px;
    }
  }
  &__label {
    font-family: 'Roboto', sans-serif;
    color: #d32f2f;
    font-size: 14px;
    text-transform: uppercase;
  }
}
.steps {
  font-weight: 400;
  font-size: 14px;
  line-height: 143%;
}
@media (max-width: 991.98px) {
  .label {
    text-transform: none;
    margin-bottom: 12px;
  }
  .grayLabel {
    display: none;
  }
  .variants {
    padding: 0 12px;
    flex-direction: column;
    row-gap: 16px;
    &__item {
      flex-direction: row-reverse;
      align-items: center;
    }
    &__text {
      text-align: left;
      font-size: 16px;
      flex: 1 1 auto;
      & br {
        display: none;
      }
    }
    &__image {
      flex: 0 1 auto;
      width: 81px;
      height: 74px;
      margin-bottom: 0;
      & .previewTile {
        width: 81px;
        height: 74px;

        &__center {
          width: 48px;
          height: 48px;
        }
      }
    }
  }

  .bodyContainer {
    row-gap: 16px;
    flex-direction: column;
  }
  .testLinkLabel {
    text-align: center;
    display: block;
    width: 100%;
    margin-bottom: 24px;
  }
}
@media (max-width: 700.98px) {
  .buttonLabel {
    width: 311px;
  }
}
.thank__text {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 32px;
  text-align: center;
}
.thank__article {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 32px;
  text-align: center;
}
