.title {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  color: #101010;
  margin-bottom: 24px;
}
.successText {
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  color: #101010;
  margin-bottom: 24px;
}

.successDescription {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  color: #101010;
  margin-bottom: 24px;
}

.blueButton,
.backButton {
  font-size: 15px;
  font-weight: 500;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: all 0.3s ease 0s;
  text-align: center;
  &:hover {
    background-color: #2490b3;
  }
}
.blueButton {
  column-gap: 16px;
  padding: 8px 22px;
  color: #f5fdff;
  background-color: #29a4cc;
}
.buttonWrapper {
  display: flex;
  justify-content: center;
}
.button {
  padding: 8px 22px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #05222B;
  background-color: #91D2E8;
  border-radius: 4px;
  overflow: hidden;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button2 {
  padding: 8px 22px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 15px;
  text-align: center;
  color: #fff !important;
  background-color: #29a4cc !important;
  border-radius: 4px;
  overflow: hidden;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 700.98px) {
  .button{
    width: 100%;
  }
}
