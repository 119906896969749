@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
* {
  padding: 0;
  margin: 0;
  border: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  min-width: 20rem;
  line-height: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

ul li {
  list-style: none;
}

img {
  vertical-align: top;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: inherit;
  font-size: inherit;
}

button {
  line-height: inherit;
  font-weight: inherit;
  font-size: inherit;
  cursor: pointer;
  color: inherit;
  background-color: transparent;
}
body {
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}
