.popupBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  border-radius: 16px;
  background-color: #f5fdff;
  padding: 24px 40px;
  position: relative;
  z-index: 1001;
  max-width: 915px;
  min-width: 660px;
}

.crossIcon {
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 15px;
  right: 30px;
  cursor: pointer;
  color: #1c5365;
  & svg {
    width: 30px;
    height: 30px;
  }
}
@media (max-width: 991.98px) {
  .popupContent {
    min-width: 560px;
    max-width: 560px;
    padding: 24px;
  }
}
@media (max-width: 700.98px) {
  .popupContent {
    min-width: 343px;
    max-width: 343px;
    padding: 16px;
  }
}
