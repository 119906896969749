.popupBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popupContent {
  border-radius: 8px;
  background-color: #a5d15c;
  padding: 24px;
  padding-top: 32px;
  position: relative;
  z-index: 1001;
  height: 336px;
  width: 237px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.image {
  width: 161px;
  height: 161px;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 16px;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    // object-fit: fill;
  }
}
.title {
  color: #101010;
  font-weight: 500px;
  font-size: 16px;
  line-height: 150%;
  margin-bottom: 16px;
}
.link {
  text-decoration: underline;
  font-weight: 500px;
  font-size: 16px;
  color: #05222b;
  margin-bottom: 16px;
  line-height: 150%;
  display: inline-block;
}
.button {
  font-weight: 500px;
  padding: 6px 16px;
  height: 36px;
  width: 76px;
  font-size: 14px;
  text-transform: uppercase;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
