.section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  min-height: 710px;
  padding: 40px 70px;
}
@media (min-height: 1050px) {
  .section {
    min-height: calc(100vh - 318px);
  }
}
.background {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #F5FDFF;
  z-index: 0;
}
.items {
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(38, 20px);
  margin-bottom: 20px;
}

.column {
  pointer-events: none;
  &:nth-child(odd) {
    margin-top: 35px;
  }
}

.cell {
  pointer-events: all;
  color: #fff;
  width: 80px;
  height: 70px;
  position: relative;
  margin: 1.4%;

  &__wrapper {
    position: relative;
  }

  &__image {
    width: 100%;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    z-index: 10;
  }
  &__center,
  &__center img {
    width: 82px;
    height: 82px;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    clip-path: polygon(25% 8%, 75% 8%, 98% 50%, 75% 94%, 25% 94%, 2% 50%);
    -webkit-clip-path: polygon(25% 8%, 75% 8%, 98% 50%, 75% 94%, 25% 94%, 2% 50%);
  }
  &__center.centerTile,
  &__center.centerTile img {
    width: 60px;
    height: 60px;
    clip-path: none !important;
    -webkit-clip-path: none  !important;
    border-radius: 0 !important;
  }
  &__center {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Inter', sans-serif;
    img {
      border-radius: 50%;
      overflow: hidden;
      object-fit: cover;
      // object-fit: fill;
    }
  }
}
.tooltip {
  pointer-events: none;
  z-index: 100;
  position: absolute;
  top: -45%;
  left: -145%;
  transform: translateX(50%);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2f5d6c;
  border-radius: 4px;

  &__content {
    padding: 5px;
    position: relative;
  }

  &__text {
    font-size: 12px;
    color: #fff;
    white-space: nowrap;
  }

  &__triangle {
    position: absolute;
    left: 46%;
    bottom: -26%;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #2f5d6c;
  }
}

.preview {
  pointer-events: none;
  position: absolute;
  top: -259%;
  left: -40%;
  z-index: 100;
  width: 140px;
  height: 180px;
  &__content {
    border-radius: 4px;
    overflow: hidden;
    background-color: #a5d15c;
    padding: 10px;
  }

  &__image {
    margin-bottom: 8px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
    width: 100%;
    height: 100%;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__text {
    text-align: center;
    font-size: 14px;
    color: #05222b;
    font-weight: 500;
  }
  &__triangle {
    position: absolute;
    left: 46%;
    bottom: 1%;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #a5d15c;
  }
}

/* ============================================================================================================================= */
.buttons {
  z-index: 3;
  color: #fff;
  display: flex;
  justify-content: flex-end;
}
.hide {
  color: #1c5365;
  font-size: 16px;
  font-weight: 700;
  padding: 11px 16px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  background-color: #f5fdff;
  border-radius: 8px;
  transition: all 0.3s ease 0s;
  &:hover {
    background-color: #bdbdbd;
  }

  &__label {
  }

  &__icon {
    & svg {
      width: 20px;
      height: 30px;
    }
  }
}
.scale {
  display: flex;
  align-items: center;
  color: #1c5365;

  &__buttonMinus {
    border: 1px solid #1c5365;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5fdff;
    border-top-left-radius: 7px;
    border-bottom-left-radius: 7px;
    transition: all 0.3s ease 0s;
    &:hover {
      background-color: #bdbdbd;
    }
  }

  &__buttonPlus {
    border: 1px solid #1c5365;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5fdff;
    border-top-right-radius: 7px;
    border-bottom-right-radius: 7px;
    border-left: none;
    transition: all 0.3s ease 0s;
    &:hover {
      background-color: #bdbdbd;
    }
  }
}
/* ============================================================================================================================= */

@media (max-width: 991.98px) {
  .items {
    grid-template-columns: repeat(38, 14.3px);
  }
  .column {
    &:nth-child(odd) {
      margin-top: 26px;
    }
  }
  .cell {
    width: 57px;
    height: 51px;

    &__center,
    &__center img {
      width: 58px;
      height: 59px;
    }
    &__center.centerTile img {
      width: 30px;
      height: 30px;
      clip-path: none !important;
      -webkit-clip-path: none  !important;
      border-radius: 0 !important;
    }
    &__center {
      font-size: 8px;
    }
  }
  .hide {
    height: 40px;
  }
}

@media (max-width: 700.98px) {
  .section {
    padding: 12px;
    min-height: 400px;
  }

  .hide {
    font-weight: 700;
    font-size: 12px;
    padding: 13px 12px;
    &__icon {
      & svg {
        width: 18px;
        height: 18px;
      }
    }
  }
}

@media (max-width: 600px) {
  .column {
    &:nth-child(odd) {
      margin-top: 16px;
    }
  }
  .items {
    grid-template-columns: repeat(38, 9px);
  }
  .cell {
    width: 36px;
    height: 32px;

    &__center,
    &__center img {
      width: 37.2px;
      height: 37.2px;
    }
    &__center.centerTile img {
      width: 30px;
      height: 30px;
      clip-path: none !important;
      -webkit-clip-path: none  !important;
      border-radius: 0 !important;
    }
    &__center {
      font-size: 5px;
    }
  }
}
.spoiler {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease 0s;
}
